<template>
  <LayoutMainPages>
    <div class="frac-pack-container">
      <Title :title="$tc('navigation.frac_pack_code')" />

      <div class="flex justify-center items-center mt-n-4xl">
        <div class="page-content">
          <div :class="imageContainerClasses">
            <SkeletonLoading v-if="globalIsLoading" class="relative z-10" borderRadius="0" />
            <ImageResize v-else class="relative z-10" src="https://img.dibbscdn.com/frac_pack_std.png" alt="frac_pack" type="frac_pack" />
            <BackgroundGlow 
              class="img-background"
              :background="'bg-background-active-nav'"
              :filter="'blur(100px)'"
            />
          </div>

          <SkeletonLoading v-if="globalIsLoading" class="mt-n-5xl" height="63px" borderRadius="0" />
          <div v-else class="self-start text-n-md font-medium mt-n-5xl">
            {{ $t('frac_pack.frac_packs_are') }}
          </div>

          <SkeletonLoading v-if="globalIsLoading" class="self-start mt-n-4xl" width="440px" height="21px" borderRadius="0" />
          <div v-else class="self-start text-n-md font-medium mt-n-4xl">
            {{ $t('frac_pack.enter_the_code') }}
          </div>

          <div class="flex w-full mt-n-5xl xl:flex-col">
            <SkeletonLoading v-if="globalIsLoading" height="42px" borderRadius="0" />
            <input v-else v-model="code" class="v2 px-n-3xl py-n-md" :placeholder="$t('frac_pack.enter_code')" data-testid="input-code" />
            <SkeletonLoading v-if="globalIsLoading" class="frac-button ml-n-lg xl:mt-n-2xl xl:ml-n-0" height="42px" borderRadius="25px" />
            <ButtonV2
              v-else
              @onClick="claimCampaign"
              :inactive="isLoading || !code"
              class="frac-button ml-n-lg xl:mt-n-2xl xl:ml-n-0"
              :label="$tc('frac_pack.get_your_frac_pack')"
              testId="btn-getYourFracPack"
              size="medium"
              wide
            />
          </div>
        </div>
      </div>
    </div>
  </LayoutMainPages>
</template>

<script>
  import { BackgroundGlow, ImageResize, LayoutMainPages, Title } from '@/components/misc';
  import ButtonV2 from '@/stories/misc/ButtonV2';
  import CLAIM_CAMPAIGN from '@/graphql/mutations/ClaimCampaign.gql';
  import PACKS from '@/graphql/queries/Packs.gql';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Promotions',
    components: {
      BackgroundGlow,
      ButtonV2,
      LayoutMainPages,
      Title,
      ImageResize,
    },
    
    data() {
      return {
        isLoading: false,
        code: '',
      };
    },
    
    computed: {
      ...mapGetters('user', [
        'isEmailVerified',
      ]),

      imageContainerClasses(){
        return {
          'relative': true,
          'frack-pack-image-container': !this.isMobileDevice,
          'frack-pack-image-container-mobile': this.isMobileDevice
        };
      },
    },
    
    methods: {
      async claimCampaign() {
        if (!this.isEmailVerified) {
          return this.showModal('EmailNotVerified');
        }

        try {
          this.isLoading = true;

          await this.$apollo.mutate({
            mutation: CLAIM_CAMPAIGN,
            variables: {
              code: this.code,
            }
          });

          const response = await this.$apollo.query({
            query: PACKS,
            fetchPolicy: 'no-cache',
          });

          const firstPack = this.lodashGet(response, 'data.current_user.packs[0]');

          this.showModal('FracPack', { pack: firstPack, });
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      }
    },
  };
</script>

<style scoped>
  .frack-pack-image-container {
    @apply relative;
    height: 341px;
    width: 228px;
  }

  .frack-pack-image-container-mobile {
    height: 229px;
    width: 153px;
  }

  .frac-pack-container {
    width: 100%;
  }

  .page-content {
    @apply flex flex-col justify-center items-center;
    width: 100%;
    max-width: 500px;
  }

  .img-background {
    @apply opacity-80;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .frac-button {
    max-width: 190px;
  }

  @media screen and (max-width: 1200px) {
    .frac-button {
      max-width: 100%;
    }
  }
</style>
